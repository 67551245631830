.App {
  text-align: center;
  position: relative;
  overflow: scroll;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.App-body {
  padding-top: 70px;
  min-height: 10vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.Card {
  border-radius: 15px;
  background-color: rgba(255,255,255,0.8);
}

.Tabs {
  padding: .5rem;
}

.Tab-single {
  padding: 1rem;
}

/* CSS for the logo */
.App-logo-custom {
  color: #fdae38;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  margin-right: 20px;
}
.App-logo-custom:hover{
  color: #ca8b2d;
}

.App-navbar {
  background-color: #264248;
  height: auto;
  width: 100%;
  z-index: 1000;
  top: 0;
  color: white;
  position: fixed;
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}

.navbar-links .nav-link,
.navbar-links .dropdown {
  color: white;
  text-decoration: none;
  margin-right: 10px;
}

.navbar-links .nav-link:hover,
.navbar-links .dropdown:hover {
  color: lightslategray;
}

.dropdown-menu {
  background-color: #264248;
}

.dropdown-item {
  color: white;
  background-color: #264248;
}

.dropdown-item:hover {
  color: lightslategray;
  background-color: #264248; /* Change to your desired hover background color */
}

.carousel-item {
  height: 100vh; /* Full height */
}

.carousel-image {
  object-fit: cover; /* Ensures the image covers the container */
  height: 100%; /* Fill the container height */
  width: 100%; /* Fill the container width */
}

/* "Random Fact" button */
.random-fact-button {
  position: fixed;
  top: 60px;
  right: 10px;
  background-color: #ca8b2d;
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1050;
  text-align: center;
  transition: background-color 0.3s ease;
}

.random-fact-button:hover {
  background-color: #ca8b6f;
}

.custom-caption {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: white;
  max-width: 600px;
  margin: 0 auto; /* Center horizontally */
  animation: fadeIn 1s ease-in-out; /* Optional animation */
}

/* Text styles */
.custom-caption h2 {
  font-size: 3rem; /* Larger heading */
  font-weight: bold;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7); /* Text shadow for contrast */
}

.custom-caption p {
  font-size: 1.5rem;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6);
}

/* Animation for fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Typing effect container */
.typing-effect {
  display: inline-block;
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflowing text */
  border-right: 2px solid white; /* Simulate cursor */
  animation: typing 2s steps(30, end) forwards, blink 0.7s step-end infinite;
  animation-fill-mode: forwards; /* Stop animation effects after completion */
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Typing animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 67%;
  }
}

/* Blinking cursor animation */
@keyframes blink {
  from {
    border-right-color: white;
  }
  to {
    border-right-color: transparent;
  }
}